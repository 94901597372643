@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
.app {
  font-family: "Guardian TextSans",Arial,Helvetica,sans-serif;
}

.color-primary{
  color: #191e28 !important;
}

.left-align {
  text-align: left;
}


.logo {
  height: 150px;
  margin: 0 0 0 20px;
}

h2 {
  letter-spacing: 0.5px;
}

.block{
  display: block;
}

.dflex {
  display: flex;
}

.flexCenter {
  justify-content: center;
}

.spaceBetween {
  justify-content: space-between;
}

.spaceAround {
  justify-content: space-around;
}

.alignCenter {
  align-items: center;
}

.carousel.carousel-slider .control-arrow{
  top: 160px !important;
  bottom: unset !important;
  margin: unset !important;
  background-color: black !important;
}

.dropdown-list{
  background-color: transparent !important;
  transition: unset;
  text-align: center !important;
  font-size: 22px !important;
  color: white !important;
}



.footer{
  display: flex;
  justify-content: space-around;
}


.imageSection{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}


/* side menu */

.mobile-menu {
  position: absolute;
  top: 54px;
  right: 0;
  content: "";
  display: block;
  /* background: #191e28; */
  z-index: 100;
  min-width: 140px;
  /* background: linear-gradient( to right, #434343, #191e28 ); */
  border-radius: 16px;
  margin: 30px 0 0 0;
  box-shadow: 15px 5px 15px #5b5b5b, 8px 10px 23px #000000;
  backdrop-filter: blur(22px);
  opacity: 0.95;
}

.mobile-menu ul {
  list-style: none;
  display: block;
  padding: 8px;
  margin: auto;
}
.mobile-menu li {
  
  text-decoration: none;
  transition: all .5s;
  
  padding: 10px;
  text-align: center;
}
.mobile-menu li a{
  color: #191e28;
  text-decoration: none;
}
.mobile-menu li:hover,
.mobile-menu li:focus {
  background: #1ABC9C;
  color: #fff;
}


.close{
  font-size: xx-large;
  font-weight: bolder;
}

.menu{
  font-size: x-large;
  font-weight: bolder;
  div{
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
  border-radius:5px
  }
}


/* text */

.hero-text{
  font-size: 70px;
  font-weight: 900;
  font-family: "Guardian TextSans",Arial,Helvetica,sans-serif;
}

.sub-hero-text{
  font-size: 40px;
  font-weight: 900;
  font-family: "Guardian TextSans",Arial,Helvetica,sans-serif;
}
.sub-hero-text2{
  font-size: 40px;
  font-family: "Guardian TextSans",Arial,Helvetica,sans-serif;
}

.column{
  padding: 20px 0;
}

@media (max-width: 768px){
  .logo{
    height: 80px;
    margin: 0;
  }
  .footer{
    flex-direction: column;
    gap: 50px;
    text-align: center;
  }
  .imageSection{
    margin: 20px 0 0 0;
  }
  .hero-text{
    font-size: 40px;
      font-weight: 900;
      font-family: "Guardian TextSans",Arial,Helvetica,sans-serif;
  }
  .sub-hero-text2{
    text-align: center;
  }
  .section3-heading{
    flex-direction: column;
  }
  .section3-heading .more{
    display: none;
  }
  .column{
    margin: 20px 0 !important;
    padding:unset;
  }
}